import React from "react";
import Clone from "./Clone";

function App() {
  return (
    <div >
      <Clone />
    </div>
  );
}

export default App;
